<template>
  <div v-if="products" id="knivesCatalog" class="knives__catalog">
    <div class="container">
      <div class="knives__catalog-top">
        <img
          src="@/assets/img/icons/knives-catalog-logo.svg"
          class="knives__catalog-logo"
          alt=""
        />
        <div class="knives__catalog-text">
          {{ $t("knives.catalogText1") }}
          <br />
          {{ $t("knives.catalogText2") }}
        </div>
        <a
          :href="
            $helpers.getAbsolutePath(
              promo.attributes.all_products.data.attributes.url
            )
          "
          download
          class="knives__catalog-btn"
        >
          {{ $t("knives.catalogDownload") }}
        </a>
      </div>

      <div class="knives__catalog-inner">
        <div
          v-for="(item, index) in products"
          :key="index"
          class="knives__catalog-item"
        >
          <img :src="imageUrl(item)" class="knives__catalog-item--img" alt="" />
          <div class="knives__catalog-item--title">
            {{
              $i18n.locale === "ru"
                ? item.attributes.label.ru
                : item.attributes.label.kk
            }}
          </div>
        </div>
      </div>
      <div class="knives__catalog-pagination">
        <div
          class="knives__catalog-pagination--prev knives__catalog-pagination--bg"
          @click="prevPage"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 18L9 12L15 6"
              stroke="#999999"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div
          class="knives__catalog-pagination--number knives__catalog-pagination--bg"
        >
          {{ pagination.page }}
        </div>
        <div
          class="knives__catalog-pagination--next knives__catalog-pagination--bg"
          @click="nextPage()"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 18L15 12L9 6"
              stroke="#999999"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="knives__catalog-pagination--pages">из {{ pageCount }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getShowcases } from "@/api/products";
import { mapState } from "vuex";

export default {
  name: "KnivesCatalog",
  data() {
    return {
      pagination: {
        page: 1,
        pageSize: 10,
      },
      pageCount: null,
      products: null,
    };
  },
  computed: {
    ...mapState("knivesModule", ["promo"]),
  },
  watch: {
    "pagination.page": {
      handler() {
        getShowcases(this.pagination).then((res) => {
          this.products = res.data;
        });
        document.querySelector("#knivesCatalog").scrollIntoView();
      },
    },
  },
  mounted() {
    getShowcases(this.pagination).then((res) => {
      this.products = res.data;
      this.pageCount = res.meta.pagination.pageCount;
    });
  },
  methods: {
    nextPage() {
      if (this.pagination.page === this.pageCount) {
        return;
      } else {
        this.pagination.page++;
      }
    },
    imageUrl(item) {
      if (item?.attributes?.image?.data) {
        return this.$helpers.getAbsolutePath(
          item?.attributes.image.data.attributes.url
        );
      }
      return require("@/" + "assets/img/icons/placeholder.svg");
    },
    prevPage() {
      if (this.pagination.page === 1) {
        return;
      } else {
        this.pagination.page--;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
